import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { Stack } from "@mui/material";
import { Link as LinkR } from 'react-scroll';

const drawerWidth = 240;
const navItems = ["Home", "Portfolio", "Buy", "Tokenomics"];

function Header(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", background: "#000235", color: "white" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        BabyFarm
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding onClick={() => scrollToSection(item)}>
            <ListItemButton sx={{ textAlign: "center", border: 0 }}>
            <LinkR to={item} smooth={true} duration={50} offset={-50}>
                  </LinkR>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Stack sx={{ display: "flex", border: 0 }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          backgroundColor: "#000235",
        }}
      >
        <Toolbar sx={{ marginTop: "20px" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" }, backgroundColor: "#000235" }}
          >
            <MenuIcon />
          </IconButton>

          <Grid container md={12} justifyContent="center" textAlign="center">
            <Grid item md={4}>
              <Typography
                variant="h6"
                component="div"
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", sm: "block" },
                  color: "#fff",
                  fontFamily: "Inter",
                  fontSize: "17px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {/* <img
                  src={BabyLogo}
                  alt="BabyLogo"
                  height={"60px"}
                  style={{ maxWidth: "100%" }}
                ></img> */}
                Baby Farm
              </Typography>
            </Grid>

            <Grid item md={8}>
              <Stack
                direction={"row"}
                spacing={10}
                sx={{
                  display: { xs: "none", sm: "block" },
                }}
              >
                {navItems.map((item) => (
                  <Button
                    key={item}
                onClick={() => scrollToSection(item)}
                    sx={{
                      color: "#fff",
                      fontFamily: "Inter",
                      fontSize: "15px",
                      fontWeight: "700",
                    }}
                  >
                      {item === 'Home' ? (
                  <LinkR to="Home" smooth={true} duration={50} offset={-50}>
                    {item}
                  </LinkR>
                ) : null}

                {item === 'Portfolio' ? (
                  <LinkR to="Portfolio" smooth={true} duration={50} offset={-50}>
                    {item}
                  </LinkR>
                ) : null}
                {item === 'Buy' ? (
                  <LinkR to="Buy" smooth={true} duration={50} offset={-50}>
                    {item}
                  </LinkR>
                ) : null}
                {item === 'Tokenomics' ? (
                  <LinkR to="Tokenomics" smooth={true} duration={50} offset={-50}>
                    {item}
                  </LinkR>
                ) : null}
                  </Button>
                ))}
              </Stack>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#000235",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
      </Box>
    </Stack>
  );
}

Header.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default Header;
